<template>
  <div>
    <a
      href="javascript:void(0)"
      class="btn btn-third btn-sm"
      @click.prevent="deleting"
    > 
      <i class="icofont icofont-close" />
    </a>
  </div>
</template>

<script>
import { DELETE_DIRECT_SMS } from '../../graphql/user'

export default {
    props: {
        object: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    methods: {
        deleting(){
              this.$apollo.mutate({
                  mutation: DELETE_DIRECT_SMS,
                  variables: {
                      "uid": this.object.uid
                  },
                  update: (data) => {
                      if(data) console.log(`Direct SMS ${this.object.uid} deleted successfully`)
                      else console.log(`Error in deletting type direct sms ${this.object.uid}`)
                  }
                })
          }
    }
}
</script>

<style>

</style>